<template>
  <ErrorLayout :errorStatus="404" />
</template>

<script>
import ErrorLayout from "../../components/ErrorLayout";

export default {
  name: "Error404",
  components: {
    ErrorLayout,
  },
};
</script>
